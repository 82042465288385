<template>
  <div class="course-detail">
    <!-- 课程列表 -->
    <div class="container">
      <div style="display: flex;position: relative;">
        <div id="answeringQuestionList"
          :class="['row-name', 'select']"
        >
          答疑列表
        </div>
        <el-row :gutter="10" class="row-name" style="position: absolute;right: 75px;" v-if="courseId != 0 && !viewReply">
          <el-col :span="12">
            <el-input placeholder="请输入关键字" v-model="keyword" size="small" clearable>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-button icon="el-icon-search" @click="handleSearch" size="small">搜索</el-button>
            <el-button icon="el-icon-back" type="danger" @click="courseId = 0" size="small" plain>返回</el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 课程列表 -->
      <div v-if="courseId === 0">
        <div class="course-list" v-if="courseList.length !== 0">
          <div class="course-item" @click="goDetail(item.id)" v-for="(item,index) in courseList" :key="index">
            <div class="course-content">
              <div class="course-img">
                <img :src="item.logo2" alt="">
              </div>
              <div class="course-content-item">
                <div class="course-name">
                  {{item.name}}
                </div>
                <div class="course-descr">
                  {{item.descr}}
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <el-pagination
            style="margin: 20px 0;"
            background
            layout="prev, pager, next"
            :total="total"
            :hide-on-single-page="true"
            @next-click="nextClick"
            @prev-click="prevClick"
            @current-change="currentChange">
          </el-pagination>
        </div>
        <div v-else>
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
      <div v-else>
        <!-- 答疑列表 -->
        <div class="answer-module" v-if="!viewReply && questionList.length > 0">
          <div class="answer-central">
            <div
              class="answer-central-section"
              @click="goDetails(item.id)"
              v-for="(item, index) in questionList"
              :key="index"
            >
              <img class="flex-width-head" v-if="item.avatar" :src="item.avatar" alt="" />
              <img class="flex-width-head" v-else src="../../assets/defaultAvatar.png" alt="" />
              
              <div class="answer-central-section-content">
                <div class="content-name">{{ item.creatorName }}</div>
                <div class="content-time">{{ item.createTime }}</div>
                <p class="content-p" v-html="item.content"></p>
              </div>
            </div>
            <el-pagination
              style="margin: 20px 0"
              @current-change="currentChange2"
              background
              layout="prev, pager, next"
              :total="total2"
              :hide-on-single-page="true"
            ></el-pagination>
          </div>
          <!-- 固定图标 -->
          <div class="answer-sidebar">
            <img @click="onAddAnswer" src="../../assets/edit.png" class="flex-width" alt="" />
            <img @click="onRefresh" src="../../assets/refresh.png" class="flex-width" alt="" />
            <a href="#answeringQuestionList"><img src="../../assets/stick.png" class="flex-width"/></a>
          </div>
        </div>
        <div v-else-if="questionList.length === 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <!-- 答疑详情 -->
        <div v-else>
          <div class="answer-module">
            <div class="answer-central">
              <img
                class="back-left"
                src="../../assets/back-left.png"
                alt=""
                height="32px"
                width="32px"
                @click="back()"
              />
              <div class="answer-central-section" v-if="questionDetail">
                <img 
                  v-if="questionDetail.avatar"
                  class="flex-width-head"
                  :src="questionDetail.avatar"
                  alt=""
                />
                <img class="flex-width-head" v-else src="../../assets/defaultAvatar.png" alt="" />
                <div class="answer-central-section-content">
                  <div class="content-name">
                    {{ questionDetail.creatorName }}
                  </div>
                  <div class="content-time">
                    {{ questionDetail.createTime }}
                  </div>
                  <p class="content-p" v-html="questionDetail.content"></p>
                </div>
              </div>
              <div class="reply-title">
                <div> {{ questionReplyList.length > 0 ? questionReplyList.length : "0" }}回复</div>
                <div class="reply-title-comment" @click="comment">
                  <div class="reply-title-comment-text">评论</div>
                  <img src="../../assets/comment.png" class="flex-width-huifu" alt="" />
                </div>
              </div>
              <div class="answer-central-detail" v-if="questionReplyList.length > 0" >
                <div
                  class="answer-central-section"
                  v-for="(item, index) in questionReplyList"
                  :key="index"
                >
                  <img class="flex-width-head" v-if="item.avatar" :src="item.avatar" alt="" />
                  <img class="flex-width-head" v-else src="../../assets/defaultAvatar.png" alt="" />
                  <div class="answer-central-section-content">
                    <div class="content-name">{{ item.creatorName }}</div>
                    <div class="content-time">{{ item.createTime }}</div>
                    <div class="content-p" v-html="item.content"></div>
                  </div>
                </div>
              </div>
              <div v-else>
                <el-empty description="暂无回复"></el-empty>
              </div>
              <el-pagination
                style="margin: 20px 0"
                @current-change="currentChange3"
                background
                layout="prev, pager, next"
                :total="total3"
                :hide-on-single-page="true"
              ></el-pagination>
            </div>
            <el-dialog
              :visible.sync="addReplyDialogVisible"
              width="850"
              center
            >
              <div class="dialog">
                <quill-editor
                  v-model="reply.content"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @change="onEditorChange($event)"
                  class="quill"
                >
                </quill-editor>
              </div>
              <div class="button">
                <el-button
                  size="mini"
                  type="primary"
                  @click="addReply"
                  class="submit"
                  >提交</el-button
                >
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021014802号</a>
    </footer>
    <el-dialog :visible.sync="addAnswerDialogVisible" width="850" center>
      <div class="dialog">
        <quill-editor
          v-model="content"
          ref="myQuillEditor"
          :options="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @change="onEditorChange($event)"
          class="quill"
        >
        </quill-editor>
      </div>
      <div class="button">
        <el-button size="mini" type="primary" @click="add" class="submit"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getQuestionList,
  addAnsweringQuestion,
  getQuestionInfo,
  getQuestionReplyList,getCourse,addQuestionReply
} from "@/api/course";
import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-extend-module";
Quill.register("modules/ImageExtend", ImageExtend);
export default {
  data() {
    return {
      classType: "",
      id: 0,
      courseInfo: {},
      keyword: "",
      courseId: 0,
      courseList:[],
      classList: [],
      limt: 10, // 每页行数
      page1: 1, // 课程列表分页
      page2: 1, // 答疑列表分页
      page3: 1, // 答疑回复列表分页
      total1: 0, // 课程列表总数
      total2: 0, // 答疑列表总数
      total3: 0, // 答疑回复列表总数
      showClass: true,
      viewReply: false,
      answerId: [0],
      questionList: [], //答疑列表
      addReplyDialogVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      textarea2: "",
      questionDetail: {}, //答疑列表
      questionReplyList: [],
      addAnswerDialogVisible: false,
      content: ``,
      editorOption: {
        modules: {
          ImageExtend: {
            // 如果不作设置，即{}  则依然开启复制粘贴功能且以base64插入
            name: "file", // 图片参数名
            size: 3, // 可选参数 图片大小，单位为M，1M = 1024kb
            action:
              "https://www.zhijiao168.com/cloudClassroom/app/api/app/system/upload/file", // 服务器地址, 如果action为空，则采用base64插入图片
            // response 为一个函数用来获取服务器返回的具体图片地址
            // 例如服务器返回{code: 200; data:{ url: 'baidu.com'}}
            // 则 return res.data.url
            response: (res) => {
              return res.data;
            },
            headers: (xhr) => {
              xhr.setRequestHeader(
                "authentication",
                localStorage.getItem("web-token")
              );
            }, // 可选参数 设置请求头部
            sizeError: () => {}, // 图片超过大小的回调
            start: () => {}, // 可选参数 自定义开始上传触发事件
            end: () => {}, // 可选参数 自定义上传结束触发的事件，无论成功或者失败
            error: () => {}, // 可选参数 上传失败触发的事件
            success: () => {}, // 可选参数  上传成功触发的事件
            change: (xhr, formData) => {}, // 可选参数 每次选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
          },
          toolbar: {
            // 如果不上传图片到服务器，此处不必配置
            container: [["image"]], // container, // container为工具栏，此次引入了全部工具栏，也可自行配置
            handlers: {
              image: function () {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id);
              },
            },
          },
        },
        placeholder: "有疑问请在这里输入",
      },
      course:{
        page: 1,
        limit: 10
      },
      reply: {
        id: 0,
        content: ''
      }
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getCourseList();
    localStorage.setItem("page", "/classList");
    localStorage.setItem("id", this.id);
  },
  methods: {
    getCourseList(){
      getCourse({limit:this.course.limt,page:this.course.page,myself: 1}).then(res => {
        this.courseList = res.data.list
        this.total = res.data.total
      })
    },
    // 添加回复
    addReply(){
      addQuestionReply({
        content: this.reply.content,
        questionId: this.reply.id,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "回复已提交，请等待审核通过",
            type: "success",
          });
          this.addReplyDialogVisible = false;
          this.reply.content=''//清空富文本框
        }
      });
    },
    add() {
      if (this.content == "") {
        this.$message.error("请输入内容");
        return;
      }
      addAnsweringQuestion({
        courseId: this.courseId,
        title: "title",
        content: this.content,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "答疑已提交，请等待审核通过",
            type: "success",
          });
          this.addAnswerDialogVisible = false;
          this.content=''//清空富文本框
        }
      });
    },
    handleSearch() {
      this.page2 = 1;
      this.getQuestion()
    },
    //答疑列表
    getQuestion() {
      getQuestionList({
        keyword: this.keyword,
        courseId: this.courseId,
        page: this.page2,
        limit: this.limit
      }).then((res) => {
        this.questionList = null;
        if (res.code == 200) {
          this.questionList = res.data.list;
          this.total2 = res.data.total;
        } else {
          alert(res.msg);
        }
      });
    },
    onEditorReady(editor) {
      // 富文本编辑框准备编辑器
    },
    onEditorBlur() {}, // 富文本编辑框失去焦点事件
    onEditorFocus() {}, // 富文本编辑框获得焦点事件
    onEditorChange() {}, // 富文本编辑框内容改变事件
    //刷新
    onRefresh() {
      this.getQuestion();
      this.$message({ message: "刷新成功", type: "success" });
    },
    //弹框
    onAddAnswer() {
      this.addAnswerDialogVisible = true;
    },
    goDetails(id) {
      this.viewReply = true;
      // this.$router.push({ name: "reply", query: { id: id } });
      this.reply.id = id;
      this.getQuestionDetail();
      this.getQuestionReply();
    },
    // 获取答疑详情
    getQuestionDetail() {
      getQuestionInfo({ id: this.reply.id }).then((res) => {
        if (res.code == 200) {
          this.questionDetail = res.data;
        } else {
          alert(res.msg);
        }
      });
    },
    // 获取答疑回复
    getQuestionReply() {
      getQuestionReplyList({
        page: this.page3,
        limit: this.limit,
        questionId: this.reply.id,
      }).then((res) => {
        if (res.code == 200) {
          this.questionReplyList = res.data.list;
          this.total3 = res.data.total;
        } else {
          alert(res.msg);
        }
      });
    },
    //点击下一页
    nextClick(){
      this.course.page = this.course.page + 1
    },
    //点击上一页
    prevClick() {
      this.course.page = this.course.page - 1
    },
    //改变页数
    currentChange(page) {
      this.course.page = page
      this.getCourseList()
    },
    // 点击评论回复
    comment() {
      this.addReplyDialogVisible = true;
    },
    back() {
      this.viewReply = false;
      this.questionDetail = {};
      this.questionReplyList = [];
    },
    // 班级列表改变页数
    currentChange1(page) {
      this.page1 = page;
    },
    // 答疑列表改变页数
    currentChange2(page) {
      this.page2 = page;
      this.getQuestion();
    },
    // 答疑回复列表改变页数
    currentChange3(page) {
      this.page3 = page;
      this.getQuestionReply();
    },
    //跳转到班级详情
    goDetail(id) {
      this.courseId = id
      this.getQuestion();
    },
  },
};
</script>

<style lang='scss' scoped>
.select {
  border-bottom: 5px solid #4394ff;
}
.notSelect {
  color: black;
  border-bottom: none;
}
.el-pagination {
  text-align: center;
}
.course-detail {
  .row-name {
    font-size: 20px;
    margin-top: 30px;
    font-weight: 400;
    margin-right: 35px;
    padding-bottom: 4px;
    cursor: pointer;
  }

  .line {
    height: 5px;
    width: 60px;
    margin: 4px 0 30px 0;
    background-color: #4394ff;
    border-radius: 5px;
  }
  .class-item {
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
    font-size: 24px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .header {
    width: 100%;
    background-color: #323368;
    .container-header {
      width: 1200px;
      margin: 0 auto;
      padding: 60px 0 80px 0;
      display: flex;
      .course-item {
        // border: 1px solid #EEEEEE;
        width: 100%;
        padding: 20px;
        margin-bottom: 20px;
        .course-type-name {
          margin-bottom: 15px;
        }
        .course-content {
          display: flex;
          .course-content-item {
            margin-left: 20px;
            flex: 1;
            .course-name {
              margin-bottom: 13px;
              font-size: 18px;
              color: #ffffff;
            }
            .course-descr {
              color: #a7a7a7;
            }
          }
        }
      }
    }
  }
}
.answer-module {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
.answer-central {
  max-width: 1200px;
  width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin: 50px 0;
  .answer-central-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    .fontWidth {
      padding: 20px 40px;
      font-size: 20px;
      font-weight: 400;
      line-height: 0px;
      margin-top: 10px;
    }
  }
  .answer-central-section {
    width: 90%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #f3f3f3;
    margin-top: 30px;
    cursor: pointer;
    .flex-width-head {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    .flex-width-content {
      width: 201px;
      height: 142px;
      opacity: 1;
      border-radius: 5px;
    }
  }
  
}
.answer-sidebar {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  position: fixed;
  bottom: 150px;
  right: 340px;
}
.flex-width {
  width: 45px;
  height: 45px;
  margin-top: 10px;
  cursor: pointer;
}
.flex-width-div {
  // align-self:flex-end;
  display: flex;
  flex-direction: row;
  // margin-bottom: ;
}
.flex-width-huifu {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}
.dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.dialog-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 300px;
  border: 1px solid #d9d9d9;
  margin-bottom: 20px;
}
.dialog-center-textarea {
  margin: 0 20px;
}
.updload {
  margin: 20px 20px;
  // background-color: blueviolet;
}
.quill {
  width: 100%;
  height: 400px;
}
.button {
  width: 100%;
  text-align: right;
  .submit {
    margin-top: 80px;
  }
}
.answer-module {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  // background-color: #F4F5F8;
}
.course-list{
  margin: 20px 0;
  .course-item{
    border: 1px solid #EEEEEE;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    .course-type-name{
      margin-bottom: 15px;
    }
    .course-content{
      display: flex;
      .course-content-item{
        margin-left: 20px;
        .course-name{
          margin-bottom: 13px;
          font-size: 18px;
        }
        .course-descr{
          color:#B1B1B1;
        }
      }
    }
  }
}
.answer-central {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin-top: 50px;
}
.answer-central-detail {
  width: 88%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.answer-central-section {
  width: 90%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 30px;
  .flex-width-head {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .flex-width-content {
    width: 201px;
    height: 142px;
    opacity: 1;
    border-radius: 5px;
  }
}
.answer-central-section-content {
  width: 90%;
  margin-left: 10px;
  margin-bottom: 30px;
  .content-name {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
  }
  .content-time {
    font-size: 8px;
    font-weight: 400;
    color: #9e9e9e;
  }
  .content-p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    width: 100%;
    ::v-deep{
      img{
         width: 100%;
      }
    }
  }
}

.flex-width-huifu {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}
.reply-title {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  .reply-title-comment {
    font-size: 18px;
    font-weight: bold;
    display: -webkit-inline-box;
    cursor: pointer;
    .reply-title-comment-text {
      color: #4394ff;
    }
  }
}
.quill {
  width: 100%;
  height: 400px;
}
.button {
  width: 100%;
  text-align: right;
  .submit {
    margin-top: 80px;
  }
}
.back-left {
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  margin: 20px;
}
.course-img{ 
   width:224px; 
   height:160px;
   overflow:hidden
} 
.course-img img{
  max-width:300px;
  height:160px;
  width: 224px;
} 
</style>